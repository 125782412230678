<template>
  <div class="outer">
    <PageContent>
      <SearchPanel
        show-btn
        flex
        @getList="getList"
      >
        <div>
          <p>姓名</p>
          <el-input
            v-model.trim="form.real_name"
            placeholder="请输入姓名"
            size="mini"
            class="w240"
            clearable
          />
        </div>
        <div>
          <p>手机号</p>
          <el-input
            v-model.number="form.mobile"
            placeholder="请输入手机号"
            size="mini"
            class="w240"
            clearable
          />
        </div>
        <div>
          <p>身份证号</p>
          <el-input
            v-model.trim="form.idcard"
            placeholder="请输入身份证号"
            size="mini"
            class="w240"
            clearable
          />
        </div>
        <el-button
          slot="button-right"
          size="mini"
          @click="clearAll"
        >
          清空
        </el-button>
        <el-button
          v-if="isRead === 0"
          slot="button-right"
          size="mini"
          class="margin-l10"
          icon="el-icon-plus"
          type="primary"
          @click="add"
        >
          新增
        </el-button>
      </SearchPanel>
    </PageContent>
    <PageContent>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        empty-text="暂无数据"
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="序号"
          width="90"
        />
        <el-table-column
          prop="real_name"
          label="姓名"
        />

        <el-table-column
          prop="mobile"
          label="手机号"
        />
        <el-table-column
          width="180"
          prop="idcard"
          label="身份证号"
        />
        <el-table-column
          width="180"
          prop="address"
          label="家庭地址"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.province_name+scope.row.city_name+scope.row.area_name+scope.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="工作状态"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.is_working?'在职':'休假' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="备注"
        >
        </el-table-column>
        <el-table-column
          v-if="isRead === 0"
          label="操作"
          width="90"
        >
          <template slot-scope="scope">
            <router-link
              :to="{path: '/employ/express/detail', query: {type: 'read',id: scope.row.id}}"
              tag="a"
              target="_blank"
              class="blue"
            >
              查看
            </router-link><br>
            <router-link
              v-if="isRead === 0"
              :to="{path: '/employ/express/detail', query: {type: 'edit',id: scope.row.id}}"
              tag="a"
              target="_blank"
              type="text"
              style="color: #3FB265 !important;"
            >
              编辑
            </router-link><br>
            <el-button
              v-if="isRead === 0"
              type="text"
              class="red"
              style="padding: 0"
              @click="deleteLabel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </PageContent>
  </div>
</template>

<script>
/**
 * 同城配送人员管理
 */



export default {
  name: 'ExpressList',
  components: {
    
    
  },
  data() {
    return {
      isRead: 1, // 权限
      tableData: [],
      form: {
        real_name: '', // 姓名
        mobile: '', // 手机号
        idcard: '' // 身份证号
      },
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.isRead = Number(sessionStorage.getItem('is_read'))
    this.getList(1)
  },
  methods: {
    add() {
      this.$router.push({ path: '/employ/express/detail', query: { type: 'add' } })
    },
    deleteLabel(item) {
      let _this = this
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then((res) => {
        _this.$axios({
          method: 'post',
          url: _this.$api.HealthCode.delete,
          data: {
            id: item.id
          }
        }).then((res) => {
          if (res.error === 0) {
            this.getList()
            this.$message({ type: 'success', message: '操作成功' })
          }
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取列表数据
    getList(index) {
      let _this = this
      _this.loading = true
      _this.$axios({
        method: 'get',
        url: _this.$api.HealthCode.get_list,
        params: {
          page: _this.pagination.currentPage,
          page_count: _this.pagination.nowPageSize,
          real_name: _this.form.real_name,
          mobile: _this.form.mobile,
          idcard: _this.form.idcard
        }
      }).then((res) => {
        if (res.error === 0) {
          _this.pagination.count = res.data.pagination.item_total
          _this.tableData = res.data.list ? res.data.list : []
        } else {
          _this.$message(r.error_msg)
          _this.tableData = []
        }
        _this.loading = false
      })
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    clearAll() {
      this.form.real_name = ''
      this.form.idcard = ''
      this.form.mobile = ''
    }
  }
}
</script>
