var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: { model: _vm.form, "label-width": "100px", rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名:", prop: "real_name" } },
            [
              _vm.type !== "read"
                ? _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入姓名", maxlength: "6" },
                    model: {
                      value: _vm.form.real_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "real_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.real_name",
                    },
                  })
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [_vm._v(" " + _vm._s(_vm.form.real_name) + " ")])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号:", prop: "mobile" } },
            [
              _vm.type !== "read"
                ? _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入手机号", maxlength: "11" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", _vm._n($$v))
                      },
                      expression: "form.mobile",
                    },
                  })
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [_vm._v(" " + _vm._s(_vm.form.mobile) + " ")])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号:", prop: "idcard" } },
            [
              _vm.type !== "read"
                ? _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入身份证号", maxlength: "18" },
                    model: {
                      value: _vm.form.idcard,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "idcard",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.idcard",
                    },
                  })
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [_vm._v(" " + _vm._s(_vm.form.idcard) + " ")])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工作状态:", prop: "is_working" } },
            [
              _vm.type !== "read"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.is_working,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_working", $$v)
                        },
                        expression: "form.is_working",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.form.is_working === 1 ? "在职" : "休假") +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "家庭住址:", prop: "siteDefault" } },
            [
              _vm.type !== "read"
                ? _c(
                    "p",
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.siteOptions,
                          placeholder: "请选择省市区",
                          options: _vm.siteArr,
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.form.siteDefault,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "siteDefault", $$v)
                          },
                          expression: "form.siteDefault",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.province_name +
                            _vm.form.city_name +
                            _vm.form.area_name
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "address" } },
            [
              _vm.type !== "read"
                ? _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入详细地址", type: "textarea" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  })
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [_vm._v(" " + _vm._s(_vm.form.address) + " ")])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注:" } },
            [
              _vm.type !== "read"
                ? _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入备注", type: "textarea" },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  })
                : _vm._e(),
              _vm.type === "read"
                ? _c("p", [_vm._v(" " + _vm._s(_vm.form.remarks) + " ")])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type !== "read"
        ? _c(
            "el-button",
            {
              staticStyle: { width: "110px", "margin-left": "100px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitStore("ruleForm")
                },
              },
            },
            [_vm._v(" 提交 ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }