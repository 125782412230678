<template>
  <div class="outer">
    <el-form
      ref="ruleForm"
      :model="form"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item
        label="姓名:"
        prop="real_name"
      >
        <el-input
          v-if="type!=='read'"
          v-model.trim="form.real_name"
          placeholder="请输入姓名"
          maxlength="6"
          style="width:200px"
        />
        <p v-if="type==='read'">
          {{ form.real_name }}
        </p>
      </el-form-item>
      <el-form-item
        label="手机号:"
        prop="mobile"
      >
        <el-input
          v-if="type!=='read'"
          v-model.number="form.mobile"
          placeholder="请输入手机号"
          maxlength="11"
          style="width:200px"
        />
        <p v-if="type==='read'">
          {{ form.mobile }}
        </p>
      </el-form-item>
      <el-form-item
        label="身份证号:"
        prop="idcard"
      >
        <el-input
          v-if="type!=='read'"
          v-model.trim="form.idcard"
          placeholder="请输入身份证号"
          style="width:200px"
          maxlength="18"
        />
        <p v-if="type==='read'">
          {{ form.idcard }}
        </p>
      </el-form-item>
      <el-form-item
        label="工作状态:"
        prop="is_working"
      >
        <el-select
          v-if="type!=='read'"
          v-model="form.is_working"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <p v-if="type==='read'">
          {{ form.is_working===1?'在职':'休假' }}
        </p>
      </el-form-item>
      <el-form-item
        label="家庭住址:"
        prop="siteDefault"
      >
        <p v-if="type!=='read'">
          <el-cascader
            v-model="form.siteDefault"
            :props="siteOptions"
            placeholder="请选择省市区"
            :options="siteArr"
            @change="handleChange"
          />
        </p>
        <p v-if="type==='read'">
          {{ form.province_name+form.city_name+form.area_name }}
        </p>
      </el-form-item>
      <el-form-item
        label=""
        prop="address"
      >
        <el-input
          v-if="type!=='read'"
          v-model="form.address"
          placeholder="请输入详细地址"
          style="width:400px"
          type="textarea"
        />
        <p v-if="type==='read'">
          {{ form.address }}
        </p>
      </el-form-item>
      <el-form-item
        label="备注:"
      >
        <el-input
          v-if="type!=='read'"
          v-model="form.remarks"
          placeholder="请输入备注"
          style="width:400px"
          type="textarea"
        />
        <p v-if="type==='read'">
          {{ form.remarks }}
        </p>
      </el-form-item>
    </el-form>

    <el-button
      v-if="type!=='read'"
      type="primary"
      style="width:110px;margin-left: 100px;"
      @click="submitStore('ruleForm')"
    >
      提交
    </el-button>
    <!-- <el-button
        style="width:110px;margin:50px 100px;"
        @click="backGo"
      >
        返回
      </el-button> -->
  </div>
</template>

<script>
export default {
  name: 'ExpressDetail',
  data() {
    return {
      form: {
        real_name: '',
        mobile: '',
        idcard: '',
        remarks: '',
        province_id: '',
        city_id: '',
        area_id: '',
        address: '',
        is_working: '',
        siteDefault: [],
        supplier_id: localStorage.getItem('admin_type_value')
      },
      rules: {
        real_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        idcard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        is_working: [
          { required: true, message: '请选择工作状态', trigger: 'change' }
        ],
        siteDefault: [
          { required: true, message: '请选择家庭住址', trigger: 'change' }
        ] // 默认地址

      },
      options: [
        {
          value: 1,
          label: '在职'
        }, {
          value: 0,
          label: '休假'
        }
      ],
      provinceArr: [],
      cityArr: [],
      addressArr: [],
      type: 'add',
      siteArr: [],
      siteOptions: {
        value: 'id',
        label: 'name',
        children: 'rows'
      }
    }
  },
  mounted() {
    this.type = this.$route.query.type
    if (this.type === 'edit' || this.type === 'read') {
      this.getInfo(this.type)
    }
    this.getAddress()
    // this.getAddressList(0, 0)
  },
  methods: {
    // 获取用户信息
    getInfo() {
      this.$axios({
        method: 'get',
        url: this.$api.HealthCode.get_info,
        params: {
          id: this.$route.query.id
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.form = r.data
          this.form.siteDefault = []
          this.form.siteDefault[0] = r.data.province_id
          this.form.siteDefault[1] = r.data.city_id
          this.form.siteDefault[2] = r.data.area_id
        }
      })
    },
    // 获取选中的地址列表
    getAddressList(id, level) {
     	this.$axios({
        method: 'get',
        url: this.$api.Address.get_address_list,
        params: {
          parent_id: id
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          if (level === 0) {
            this.provinceArr = r.data
            this.cityArr = []
            this.addressArr = []
          } else if (level === 1) {
            this.cityArr = r.data
            this.form.city_id = ''
            this.form.address = ''
            this.addressArr = []
          } else {
            this.form.address = ''
            this.addressArr = r.data
          }
          return r.data
        }
      })
    },
    getAddress() {
      this.$axios({
        method: 'get',
        url: this.$api.Address.get_third_address
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.siteArr = r.data
        }
      })
    },
    // 保存
    submitStore(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.type === 'edit' ? this.$api.HealthCode.update : this.$api.HealthCode.add, {
            id: this.type === 'edit' ? this.$route.query.id : '',
            ...this.form

          }).then((res) => {
            let r = res
            if (r.error === 0) {
              this.$router.push('/employ/express/list')
              this.$message({ type: 'success', message: '保存成功' })
            }
          })
        } else {
          return false
        }
      })
    },
    handleChange(val) {
      this.form.province_id = val[0]
      this.form.city_id = val[1]
      this.form.area_id = val[2]
    },
    backGo() {
      this.$router.go(-1)
    }
  }
}
</script>
