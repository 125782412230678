var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c(
        "PageContent",
        [
          _c(
            "SearchPanel",
            {
              attrs: { "show-btn": "", flex: "" },
              on: { getList: _vm.getList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("姓名")]),
                  _c("el-input", {
                    staticClass: "w240",
                    attrs: {
                      placeholder: "请输入姓名",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.real_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "real_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.real_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("手机号")]),
                  _c("el-input", {
                    staticClass: "w240",
                    attrs: {
                      placeholder: "请输入手机号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", _vm._n($$v))
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("身份证号")]),
                  _c("el-input", {
                    staticClass: "w240",
                    attrs: {
                      placeholder: "请输入身份证号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.idcard,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "idcard",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.idcard",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "button-right", size: "mini" },
                  on: { click: _vm.clearAll },
                  slot: "button-right",
                },
                [_vm._v(" 清空 ")]
              ),
              _vm.isRead === 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "margin-l10",
                      attrs: {
                        slot: "button-right",
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: { click: _vm.add },
                      slot: "button-right",
                    },
                    [_vm._v(" 新增 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "PageContent",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "empty-text": "暂无数据",
                "header-cell-style": {
                  background: "#D9DDE1",
                  color: "#0D0202",
                  "font-weight": 400,
                },
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "序号", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { prop: "real_name", label: "姓名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "手机号" },
              }),
              _c("el-table-column", {
                attrs: { width: "180", prop: "idcard", label: "身份证号" },
              }),
              _c("el-table-column", {
                attrs: { width: "180", prop: "address", label: "家庭地址" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.province_name +
                                scope.row.city_name +
                                scope.row.area_name +
                                scope.row.address
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "工作状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.is_working ? "在职" : "休假")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "备注" },
              }),
              _vm.isRead === 0
                ? _c("el-table-column", {
                    attrs: { label: "操作", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue",
                                  attrs: {
                                    to: {
                                      path: "/employ/express/detail",
                                      query: { type: "read", id: scope.row.id },
                                    },
                                    tag: "a",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(" 查看 ")]
                              ),
                              _c("br"),
                              _vm.isRead === 0
                                ? _c(
                                    "router-link",
                                    {
                                      staticStyle: {
                                        color: "#3FB265 !important",
                                      },
                                      attrs: {
                                        to: {
                                          path: "/employ/express/detail",
                                          query: {
                                            type: "edit",
                                            id: scope.row.id,
                                          },
                                        },
                                        tag: "a",
                                        target: "_blank",
                                        type: "text",
                                      },
                                    },
                                    [_vm._v(" 编辑 ")]
                                  )
                                : _vm._e(),
                              _c("br"),
                              _vm.isRead === 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "red",
                                      staticStyle: { padding: "0" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteLabel(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1404904077
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.count,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }